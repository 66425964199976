import React from "react"
import ProfileRouteAnonymous from "../../../../../../components/auth/ProfileRoute/Anonymous"
import RequestExclusive from "../../../../../../components/RequestCustom/RequestExclusive"

const RequestExclusivePage = props => (
  <ProfileRouteAnonymous>
    <RequestExclusive url={props.location.href} />
  </ProfileRouteAnonymous>
)

export default RequestExclusivePage
